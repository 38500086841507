<template>
  <div class="alphabet">
    <h1>Recherche par ordre alphabétique</h1>
    <router-link v-for="(l, index) in arrayAlphabet" :key="index" :to="{ name: 'Lettre', params: { lettre: l }}">
       <span v-if="index !== 0" > / </span>
       {{ l.toUpperCase() }}
    </router-link>
    <router-view :key="$route.path" />
  </div>
</template>

<script>
export default {
  name: 'Alpha',
  data () {
    return {
      alphabet: 'abcdefghijklmnopqrstuvwxyz',
      arrayAlphabet: []
    }
  },
  mounted () {
    this.arrayAlphabet = this.alphabet.split('')
  }
}
</script>

<style scoped>
  .card-container {
    display: flex;
    flex-wrap: wrap;
    flex-grow: 1;
    align-items: center;
    justify-content: center;
  }
  .card {
    padding: 15px;
    transition: transform .3s ease-out;
  }
  .card:hover {
    transform: translate(0, -10px);
  }
</style>
